/**
 * @author pavlovicn, created on 8/18/2021.
 */
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {store} from '../store.js'

import ErrorPage from "../components/package/ErrorPage";

ReactDOM.render(
    <Provider store={store}>
        <ErrorPage errorCode={401}/>
    </Provider>,
    document.getElementById('errorPageIndex401')
)


